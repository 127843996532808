<us2-dialog-container [config]="config" class="dialog-container">

  <div class="action-buttons flex flex-row gap-x-5 w-full" us2-dialog-actions>

    @if (!config.hideCancelBtn) {
      <button us2-button stroked mat-dialog-close>
        {{ config.cancelBtnLabel }}
      </button>
    }

    <button [color]="config.confirmBtnColor" [mat-dialog-close]="true" us2-button>
      {{ config.confirmBtnLabel }}
    </button>

  </div>

</us2-dialog-container>
