import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ConfirmationDialogConfig } from '@shared/dialogs/confirmation-dialog/confirmation-dialog-config';
import { ButtonComponent } from '@shared/ui-components/button/button.component';
import { DialogContainerComponent } from '@shared/ui-components/dialog-container/dialog-container.component';

@Component({
  selector: 'us2-confirmation-dialog',
  standalone: true,
  imports: [
    DialogContainerComponent,
    MatDialogModule,
    ButtonComponent,
  ],
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationDialogComponent {
  config: ConfirmationDialogConfig;
  defaultConfig: Partial<ConfirmationDialogConfig> = {
    title: $localize `Confirmation`,
    cancelBtnLabel: $localize `Cancel`,
    confirmBtnLabel: $localize `OK`,
    confirmBtnColor: 'primary',
    hideCancelBtn: false,
  };

  constructor(@Inject(MAT_DIALOG_DATA) private data: { config: ConfirmationDialogConfig }) {
    this.setData();
  }

  private setData(): void {
    if (!this.data) {
      return;
    }

    this.config = { ...this.defaultConfig, ...this.data.config };
  }
}
